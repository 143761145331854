<template>
    <div class="admin-content">
        <h3 v-text="state.defaults.BoardName"></h3>
        <div class="flex-wrap right">
            <div>
                <div class="btn-wrap">
                    <button type="button" v-on:click="methods.board.movePrev" class="btn-box blue">이전글</button>
                    <button type="button" v-on:click="methods.board.moveNext" class="btn-box green">다음글</button>
                    <button type="button" v-on:click="methods.board.moveList" class="btn-box">목록</button>
                    <button type="button" v-on:click="methods.board.moveEdit" class="btn-box purple">수정</button>
                    <button type="button" v-on:click="methods.board.doRemove" class="btn-box red">삭제</button>
                </div>
            </div>
        </div>
        <div class="table-wrap table-list none-scroll-y mt50">
            <div class="table-box">
                <table v-if="state.articles">
                    <colgroup>
                        <col width="15%">
                        <col width="20%">
                        <col width="15%">
                        <col width="20%">
                        <col width="15%">
                        <col width="20%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th scope="row">제목</th>
                            <td v-text="state.articles.Title" colspan="6"></td>
                        </tr>
                        <tr>
                            <th scope="row">구분</th>
                            <td v-text="state.articles.CodeName" colspan="6"></td>
                        </tr>
                        <tr v-if="state.articles.BoardCategory === 'RECCM001'">
                            <th scope="row">입사지원서 양식</th>
                            <td v-text="state.articles.FORM_NM" colspan="6"></td>
                        </tr>
                        <tr>
                            <th scope="row">게시기간</th>
                            <td v-text="state.articles.PiredDt" colspan="6"></td>
                        </tr>
                        <tr>
                            <th scope="row">상단글 고정기간</th>
                            <td v-text="state.articles.DispAnnounceDt" colspan="6"></td>
                        </tr>
                        <tr>
                            <th scope="row">내용</th>
                            <td v-html='state.articles.Content' colspan="6"></td>
                        </tr>
                        <tr v-if="state.defaults.IsComment === 'Y'">
                            <th>댓글</th>
                            <td colspan="6">
                                <button type="button" class="btn-box green"
                                    v-on:click="methods.comment.instCmmt">댓글달기</button>
                                <table style="width:100%" border="1">
                                    <thead>
                                        <tr>
                                            <th style="width:50%">내용</th>
                                            <th>작성자</th>
                                            <th>작성일</th>
                                            <th style="width:20%">버튼</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="cmmt in state.cmmtData" v-bind:key="cmmt">
                                            <td><input type="text" v-model="cmmt.Content" class="input-text mr10"
                                                    style="width:100%;" /></td>
                                            <td>{{ cmmt.EmplName }}</td>
                                            <td>{{ cmmt.CreateDate }}</td>
                                            <td>
                                                <div class="btn-wrap">
                                                    <button type="button" v-on:click="methods.comment.saveCmmt(cmmt);"
                                                        class="btn-box gray">수정</button>
                                                    <button type="button" v-on:click="methods.comment.rmveCmmt(cmmt);"
                                                        class="btn-box purple">삭제</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="state.cmmtData.length === 0">
                                            <td colspan="4">코멘트 정보가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr v-if="state.defaults.AttFileCount > 0">
                            <th>파일첨부</th>
                            <td colspan="6">
                                <!-- [check-this] 파일첨부 IsContent = "N" && IsThumb = "N" .,. -->
                                <FileUploader ref="fileUploader1" :subUrl="store.getters.boardSubDirectoryPath"
                                    :maxFileCount="state.defaults.AttFileCount" :attachments="state.attFiles"
                                    :isBtnShow="false" />
                            </td>
                        </tr>
                        <!-- [check-this] '컨텐츠 이미지' as-is 주석 처리 되어있음. /  IsContent = "Y" && IsThumb = "N" -->
                        <tr v-if='state.defaults.UseThumbImg === "Y"'>
                            <th>섬네일</th>
                            <td colspan="6">
                                <!-- [check-this] '섬네일' IsContent = "N" && IsThumb = "Y" -->
                                <FileUploader ref="fileUploader1" :subUrl="store.getters.boardSubDirectoryPath"
                                    :maxFileCount="state.defaults.AttFileCount" :attachments="state.thumbImg"
                                    :isBtnShow="false" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive } from 'vue';
import { toRaw, ref } from '@vue/reactivity'
import { useStore } from 'vuex';
import { onMounted, watch } from '@vue/runtime-core';
import { useRoute } from "vue-router";
import { notice } from '@/modules/site/PCM_SM0810R.js';
import FileUploader from '@/components/FileUploader.vue';
import alertManager from '@/utils/alert-confirm-manager';
import router from '@/router'
export default ({
    components: { FileUploader },
    setup() {
        const _vrt = useRoute();
        const _userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
        const fileUploader1 = ref(null), fileUploader2 = ref(null);
        const store = useStore();
        var state = reactive({
            defaults: {},   // # 게시판 설정 정보
            articles: {},   // # 게시글 정보
            attFiles: [],   // # 첨부파일 [check-this] as-is... nas 등 관련 로직 처리... 검토 필요.
            boardImg: [],   // # 콘텐츠이미지 [check-this] as-is... nas 등 관련 로직 처리... 검토 필요.
            thumbImg: [],   // # 첨부파일(섬네일?) [check-this] as-is... nas 등 관련 로직 처리... 검토 필요.
            prevData: [],   // # 이전글 정보
            nextData: [],   // # 다음글 정보
            cmmtData: []    // # 코멘트 정보
        });

        /**
         * # Create Default Parameter for all service
         */
        var getParameter = function () {
            var prm = new Object();
            prm["Seq"] = _vrt.query.Seq;
            prm["BoardSeq"] = _vrt.query.Seq;
            prm["SystCode"] = notice.systemCode;
            prm["BoardCategory"] = _vrt.query.boardcategory;
            prm["GroupNo"] = "0";
            prm["UserId"] = _userInfo.EmplID;
            prm["Title"] = "";
            prm["Content"] = "";
            prm["UseYn"] = "";
            prm["UserNo"] = "";
            prm["CodeVal"] = "";
            prm["ExpiredDt"] = "";
            return prm;
        };

        /**
         * # Get Comment Data
         */
        const getComments = () => {
            notice.getBoardCommentList(getParameter()).then((res) => {
                state.cmmtData = res.cmmtData;
            });
        };

        /**
         * # Get Comment Entity
         */
        const getEttOfComment = function () {
            return {
                Seq: "0",
                SystCode: notice.systemCode,
                Sort: "",
                BoardCategory: _vrt.query.boardcategory,
                BoardSeq: _vrt.query.Seq,
                OrderNo: "",
                DepthNo: "1",
                ParentNo: "0",
                Content: "",
                UserNo: "",
                Email: "",
                UseYn: "Y"
            };
        };

        const methods = {
            board: {
                /**
                 * # 이전글
                 */
                movePrev: () => {
                    if (state.prevData.length > 0) {
                        // var q = _vrt.query;
                        // q.Seq = state.prevData[0].Seq, q.path = "PCM_SM0811D";
                        // router.push({ name: 'PCM_SM0000T', query: q });
                        router.push({
                            name: 'PCM_SM0811D',
                            query: {
                                boardcategory:_vrt.query.boardcategory,
                                Seq: state.prevData[0].Seq
                            }
                        });
                    } else {
                        alertManager.alert.warning("마지막 글입니다.");
                    }
                },
                /**
                 * # 다음글
                 */
                moveNext: () => {
                    if (state.nextData.length > 0) {
                        // var q = _vrt.query;
                        // q.Seq = state.nextData[0].Seq, q.path = "PCM_SM0811D";
                        // router.push({ name: 'PCM_SM0000T', query: q });
                        router.push({
                            name: 'PCM_SM0811D',
                            query: {
                                boardcategory:_vrt.query.boardcategory,
                                Seq: state.nextData[0].Seq
                            }
                        });
                    } else {
                        alertManager.alert.warning("마지막 글입니다.");
                    }
                },
                /**
                 * # 목록
                 */
                moveList: () => {
                    // [check-this] as-is list stateless ... same !!
                    var q = _vrt.query;
                    delete q.Seq;
                    router.push({ name: 'PCM_SM0810R', query: q });
                },
                /**
                 * # 수정
                 */
                moveEdit: () => {
                    var q = _vrt.query;
                    q["Post"] = "U";
                    router.push({ name: 'PCM_SM0812E', query: q });
                },
                /**
                 * # 삭제
                 */
                doRemove: () => {
                    var sf = function (x) {
                        if (x) {
                            let zf = (res) => {
                                alertManager.alert.success("삭제되었습니다.").then((z) => { methods.board.moveList(); });
                            };
                            notice.deleteBoardContent(getParameter()).then(zf);
                        }
                    };
                    alertManager.confirm.question("삭제확인", "삭제하시겠습니까?").then(sf);
                },
                /**
                 * # 첨부파일 다운로드
                 * @param Object item 
                 */
                downFile: function (item) {
                    // [check-this] as-is up&download rule 엉망이다.. 차후 확정되면..
                    console.log("# downFile : ", item);
                    alert("fix-me");
                }
            },
            comment: {
                /**
                 * # remove comment data
                 * @param object item 
                 */
                rmveCmmt: (item) => {
                    alertManager.confirm.question("삭제확인", "삭제하시겠습니까?").then((x) => {
                        if (x) notice.deleteBoardComment(item).then((res) => { alertManager.alert.success("삭제되었습니다."); getComments(); });
                    });
                },
                /**
                 * # insert comment
                 */
                instCmmt: () => {
                    state.cmmtData.push(getEttOfComment());
                },
                /**
                 * # save comment
                 * @param object item 
                 */
                saveCmmt: function (item) {
                    // [check-this] as-is validation X
                    notice.saveBoardComment(item).then((res) => { alertManager.alert.success("저장 되었습니다."); getComments(); });
                }
            }
        };

        onMounted(() => {
            notice.getBoardInfo(_vrt.query.boardcategory).then((res) => {
                if (res) {
                    state.defaults = res;

                    notice.getNoticeDetail(getParameter()).then((res) => {
                        state.articles = res.articles;
                        state.attFiles = res.attFiles;
                        state.boardImg = res.boardImg;
                        state.thumbImg = res.thumbImg;

                        // [check-this] text-arae -> 웹에디터로 변경되면 캐리지 리컨 하지 않아도 됨. 우선 임시로..
                        // state.articles.Content = state.articles.Content.replace(/(?:\r\n|\r|\n)/g, "<br />");
                    });
                    notice.getKRTBoardContentPreNext(getParameter()).then((res) => {
                        state.prevData = res.prevData, state.nextData = res.nextData;
                    });

                    if (state.defaults.IsComment === "Y") getComments();
                }
            });
        });

        return { state, methods, store, fileUploader1, fileUploader2 };
    }
})
</script>